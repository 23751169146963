<template>
  <div>
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><a @click="prev">土地租赁</a></el-breadcrumb-item>
          <el-breadcrumb-item>{{titleSeeMore}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <div class="goodsType" ref="goodsTypeBoxs">
        <div class="goodsType_onoff" @click="goodsTypeonoff">
          <div class="go_arrow_up" v-show="onoffShow"></div>
          <div class="go_arrow_down" v-show="!onoffShow"></div>
        </div>
        <el-row class="goodsType_row" v-for="(item,index) in menulist" :key="index">
          <el-col :span="3" class="goodsType_rowLeft">{{item.name}}</el-col>
          <el-col :span="1">
            <span
              @click="searchType('',1,item.name)"
              :class="{'chooseType':clickedAll==item.name}"
              :ref="item.id"
            >不限</span>
          </el-col>
          <el-col :span="18">
            <div class="goodsType_rowRight" :ref="index">
              <span
                v-for="(itemc,indexc) in item.childNode"
                :key="indexc"
                @click="searchType(itemc.name,2,itemc.name)"
                :class="{'chooseType':clicked==itemc.name}"
                :ref="itemc.name"
              >{{itemc.name}}</span>
            </div>
          </el-col>
          <el-col :span="2" class="marketType_BtnBox">
            <!-- <div @click="belong(index,'more')" :ref="'more'+index" class="marketType_moreBtn">
              更多
              <i class="el-icon-arrow-down"></i>
            </div>
            <div @click="belong(index,'hide')" :ref="'hide'+index" class="marketType_hideBtn">
              收起
              <i class="el-icon-arrow-up"></i>
            </div>-->
          </el-col>
        </el-row>
        <el-row class="goodsType_row">
          <el-col :span="3" class="goodsType_rowLeft">选择区域</el-col>
          <el-col :span="1">
            <span @click="searchType('',1,'')">不限</span>
          </el-col>
          <el-col :span="18">
            <div class="goodsType_rowRight addRight">
                <addressSelect @mAddressInfo="placeInfo" :address="productAddress"></addressSelect>
            </div>
          </el-col>
          <el-col :span="2" class="marketType_BtnBox">

          </el-col>
        </el-row>
      </div>
      <div class="goodsMain">
        <div class="goodsMainleft goodsMainleft2">
          <div class="gml_top">
            <!-- <div class="gml_top_block_sp">热门查询</div>
            <div
              class="gml_top_block"
              v-for="(item,index) in hotmenulist"
              :key="index"
              @click="searchType(item.id,2,item.name)"
              :class="{'chooseType':clicked==item.name}"
            >{{item.name}}</div> -->
          </div>
          <div class="gml_bottom">
            <div class="goodsBlock goodsBlock2" v-for="(item,index) in pagelist" :key="index">
              <div class="goodsBlockInside" @click="toDealDetail(item.id)">
                <div class="marketImgBox">
                  <img :src="item.imgs[0]" alt />
                </div>
                <div class="marketfont">
                  <div class="market_Price">¥{{item.price}}万元</div>
                  <div class="market_account">{{item.title}}</div>
                  <!-- <div class="market_place">发货地：{{item.province}}</div> -->
                  <!-- <div class="market_seller">{{item.name}}</div> -->
                </div>
              </div>
            </div>
            <div class="nogoods" v-show="showTips">
              <img src="../../../public/static/img/market/暂无分类@3x.png" />
              当前分类暂无商品
            </div>
          </div>
          <div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="datalength"
              :page-size="pageSize"
              :current-page.sync="pageNum"
              @current-change="paginationChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/market/market.css";
import addressSelect from '@/component/muneAddressSelect/index.vue';

export default {
  components: {
    addressSelect
  },
  data() {
    return {
      primaryMenuId: this.$route.query.id,
      productTypeId: "",
      secondaryMenuId: "",
      menulist: [
        {
          name: '农业用地',
          childNode: [
            {name: '耕地'},
            {name: '林地'},
            {name: '园地'},
            {name: '草地'},
            {name: '养殖用地'},
            {name: '农房'},
            {name: '水域'}
          ]
        },
        {
          name: '建设用地',
          childNode: [
            {name: '工矿仓储'},
            {name: '商业及住宅'},
            {name: '其他用地'}
          ]
        }
      ],
      hotmenulist: [],
      pagelist: [],
      randomlist: [],
      datalength: 0,
      pageNum: 1,
      pageSize: 15,
      loading: false,
      onoffShow: true,
      showTips: false,
      clicked: "",
      clickedAll: "",
      productAddress: {
        disabled: false, // 只为了显示不能选择地址
        provinceId: '',
        cityId: '',
        districtId: ''
      },
      province: '',
      city: '',
      district: '',
      landtype: '',
      titleSeeMore: ''
    };
  },
  mounted() {
    // this.marketSecondList();
    const seeMore=this.$route.query.seeMore;
    if (parseInt(seeMore) === 0){
      this.titleSeeMore = '精彩推荐';
    } else {
      this.titleSeeMore = '猜你喜欢';
    }
    this.marketHotList();
    this.marketRandomList();
    this.marketPageList();
  },
  methods: {
    prev() {
      window.history.go(-1);
    },
    /**跳转更多页 */
    gotoSeeMore(val){
       this.$router.push({
         path: '/market/landLeasingMore',
         query: {
           seeMore: val
         }
       });
    },
    /** 返回三级地址信息 */
    placeInfo(val) {
        this.province = val[0].id; // 居住地址 省id
        this.city = val[1].id;         // 居住地址 市id
        this.district = val[2].id; // 居住地址 区id
        this.marketPageList();
    },
    goodsTypeonoff() {
      if (this.onoffShow) {
        this.$refs["goodsTypeBoxs"].style.height = "auto";
      } else {
        this.$refs["goodsTypeBoxs"].style.height = "120px";
      }
      this.onoffShow = !this.onoffShow;
    },
    belong(name, type) {
      const isShow = "more" + name,
        isHide = "hide" + name;
      if (type == "more") {
        this.$refs[isShow][0].style.display = "none";
        this.$refs[isHide][0].style.display = "block";
        this.$refs[name][0].style.height = "auto";
        this.$refs[name][0].style.overflow = "auto";
      } else {
        this.$refs[isShow][0].style.display = "block";
        this.$refs[isHide][0].style.display = "none";
        this.$refs[name][0].style.height = "60px";
        this.$refs[name][0].style.overflow = "hidden";
      }
    },
    toDealDetail(id) {
      this.$router.push({
        path: "/market/landLeasingDetail",
        query: {
          id,
          flag: "rent"
        }
      });
    },
    paginationChange(val) {
      this.pageNum = val;
      this.marketPageList();
    },
    // async marketfirstList() {
    //   const api = this.$fetchApi.marketfirstList.api;
    //   const data = await this.$fetchData.fetchGet({}, api, "json");
    //   if (data.code === "200" && data.result) {
    //     console.log(data.result);
    //   }
    // },
    async marketSecondList() {
      const api = this.$fetchApi.marketSecondList.api + this.primaryMenuId;
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if (data.code === "200" && data.result) {
        this.menulist = data.result;
      }
    },
    async marketHotList() {
      const api = this.$fetchApi.marketHotList.api + this.primaryMenuId + "/6";
      const data = await this.$fetchData.fetchGet({}, api, "json");
      if (data.code === "200" && data.result) {
        this.hotmenulist = data.result;
      }
    },
    async marketRandomList() {
      const api = this.$fetchApi.queryLandTransferList.api;
      const data = await this.$fetchData.fetchPost({
          pageNum: 1,
          pageSize: 2,
          recommend: true
        }, api, "json");
      if (data.code === "200" && data.result) {
        this.randomlist = data.result.rows;
      }
    },
    async marketPageList() {
      const api = this.$fetchApi.queryLandTransferList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          recommend: true,
          province: this.province,
          landtype: this.landtype,
          city: this.city,
          district: this.district
        },
        api,
        "json"
      );
      if (data.code === "200" && data.result) {
        this.datalength = data.result.total;
        this.pagelist = data.result.rows;
        if (this.pagelist.length == 0) {
          this.showTips = true;
        } else {
          this.showTips = false;
        }
      }
    },
    searchType(tname, num, name) {
     

      if (num === 1) {
        this.clickedAll = name;
        this.clicked = "";
        this.landtype=tname;
        this.province = '';
        this.city = '';
        this.district = '';
        this.productAddress=  {
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
        };
        this.marketPageList();
      }
      if (num === 2) {
        this.clicked = name;
        this.clickedAll = "";
        this.landtype = tname;
        this.marketPageList();
      }
    }
  }
};
</script>
<style scoped>
</style>